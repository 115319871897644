import React, { createContext, useContext, useReducer, useMemo, useEffect } from 'react';
import { node } from 'prop-types';
import { getClientConfig } from '../services/clientConfig';
import ProgressBar from '../components/ProgressBar';

// ---------------------------------------------------
// Auth State Reducer
// ---------------------------------------------------

const initialState = {
  clientConfigResponse: {
    data: null,
    status: 'loading',
    error: null,
  },
  userProfileResponse: {
    data: null,
  },
};

function appReducer(state, action) {
  switch (action.type) {
    case 'setUserProfile':
      return { ...state, userProfileResponse: { ...state.userProfileResponse, ...(action.payload || {}) } };
    case 'setClientConfig':
      return { ...state, clientConfigResponse: { ...state.clientConfigResponse, ...(action.payload || {}) } };
    default:
      return state;
  }
}

const AuthContext = createContext();

function AuthProvider(props) {
  const [state, dispatch] = useReducer(appReducer, initialState);

  // Fetch Config
  useEffect(() => {
    dispatch({ type: 'setClientConfig', payload: { status: 'loading' } });
    getClientConfig()
      .then(res => {
        dispatch({ type: 'setClientConfig', payload: { status: 'success', data: res.data } });
      })
      .catch(error => {
        dispatch({ type: 'setClientConfig', payload: { status: 'error', error } });
      });
  }, []);

  const value = useMemo(() => {
    const { clientConfigResponse, userProfileResponse } = state;
    return {
      clientConfig: clientConfigResponse?.data,
      clientConfigError: clientConfigResponse?.error,
      userProfile: userProfileResponse?.data,
      dispatchEvent: dispatch,
    };
  }, [state]);

  if (state.clientConfigResponse.status === 'loading') {
    return <ProgressBar blueColor />;
  }

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: node.isRequired,
};

function useAuthState() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuthState };
