import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Layout } from 'antd';
//import LogoImg from '../../common/img/empire_logo.svg';
import { header, logo } from './Header.style';

const { Header } = Layout;

const LogoImg = '/login/api/authentication/fetch/anthem-branding-expanded-image.png';
const AppTitle = () => {
  return (
    <Link id="errorConsole-logo" to="/">
      <img src={LogoImg} alt="Anthem Logo" />
    </Link>
  );
};

export default function AppHeader() {
  return (
    <Header id="public-app-header" css={header}>
      <Row gutter={24} className="headerRow" type="flex" justify="space-between" align="middle">
        <Col xs={8} sm={12} md={12} lg={12} css={logo}>
          <AppTitle />
        </Col>
      </Row>
    </Header>
  );
}
