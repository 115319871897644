import { css } from '@emotion/core';

export const cssLayout = css`
  min-height: 100%;
  /* background: transparent
    linear-gradient(to bottom, #58abdf 0%, #286ce2 300px, #58abdf 300px, #f7f7f7 300px, #f7f7f7 100%); */
  /* background: transparent linear-gradient(90deg, #58abdf 50%, #286ce2 100%) 0% 0% no-repeat padding-box; */
  background: transparent linear-gradient(90deg, #f7f7f7 0%, #f7f7f7 100%) 0% 0% no-repeat padding-box;
  background-size: 100% 300px;
  box-shadow: -4px -4px 10px #ffffffcc;
  opacity: 1;
`;

export const cssContent = css`
  padding: 0px 0px;
  @media (max-width: 1024px) {
    padding: 0;
    flex: none;
  }
`;

export const cssFooter = css`
  background: #666666;
  margin-top: 2em;
  text-align: center;
  color: #fff;
  padding: 2em 2em;
`;
