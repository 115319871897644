import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';
import { node } from 'prop-types';
import CSSCacheProvider from './CSSCacheProvider';
import { AuthProvider } from './AuthContext';

const basename = '/login';

const queryConfig = {
  useErrorBoundary: false,
  refetchAllOnWindowFocus: false,
  refetchOnMount: false,
  retry: false,
};

function AppProviders({ children }) {
  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <CSSCacheProvider value={{ key: 'app' }}>
        <Router basename={basename}>
          <AuthProvider>{children}</AuthProvider>
        </Router>
      </CSSCacheProvider>
    </ReactQueryConfigProvider>
  );
}

AppProviders.propTypes = {
  children: node,
};

export { AppProviders };
