import React from 'react';
import { node, object, func } from 'prop-types';
import { Layout, BackTop } from 'antd';
import Header from './../Header/Header';

import { cssContent, cssLayout } from './AppLayout.style';

import FooterComponent from '../Footer/Footer';

const { Content } = Layout;

export default function AppLayout(props) {
  //Initiate Idle Timeout component only if user is logged in.

  return (
    <Layout id="internal-app-layout" css={cssLayout}>
      <Header id="internal-app-header" userProfile={props.userProfile} onUserMenuClick={props.onUserMenuClick} />
      <Content id="internal-app-content" css={cssContent}>
        {props.children}
      </Content>
      <FooterComponent />
      <BackTop id="internal-app-backToTop" />
    </Layout>
  );
}

AppLayout.propTypes = {
  children: node,
  userProfile: object,
  onUserMenuClick: func,
};
